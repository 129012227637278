
import Card from "@/components/base/card/Card"

export default {
    name: "RegionCenter",
    components: { Card },
    props: {
        /** Full region object */
        region: {
            type: Object,
            default: () => ({}),
        },
    },
    data: () => ({
        randomLocations: [],
        locations: [
            {
                img: [
                    "linear-gradient(#00000055, #00000055)",
                    require("@/assets/images/region/freyung-grafenau/cities/waldkirchen.jpg"),
                ],
                title: "Waldkirchen",
                alt: "Waldkirchen",
                route: "/ausbildung/waldkirchen-2814880?distance=10&category&latitude=48.7327&longitude=13.60082",
            },
            {
                img: [
                    "linear-gradient(#00000055, #00000055)",
                    require("@/assets/images/region/freyung-grafenau/cities/freyung.jpg"),
                ],
                title: "Freyung",
                alt: "Freyung",
                route: "/ausbildung/freyung-2924850?distance=10&category&latitude=48.80952&longitude=13.54768",
            },
            {
                img: [
                    "linear-gradient(#00000055, #00000055)",
                    require("@/assets/images/region/freyung-grafenau/cities/grafenau.jpg"),
                ],
                title: "Grafenau",
                alt: "Grafenau",
                route: "/ausbildung/grafenau-2918365?distance=10&category&latitude=48.85768&longitude=13.3974",
            },
            {
                img: [
                    "linear-gradient(#00000055, #00000055)",
                    require("@/assets/images/region/freyung-grafenau/cities/neureichenau.jpg"),
                ],
                title: "Neureichenau",
                alt: "Neureichenau",
                route: "/ausbildung/neureichenau-2864324?distance=10&category&latitude=48.74861&longitude=13.74699",
            },
            {
                img: [
                    "linear-gradient(#00000055, #00000055)",
                    require("@/assets/images/region/freyung-grafenau/cities/neuschoenau.jpg"),
                ],
                title: "Neuschönau",
                alt: "Neuschönau",
                route: "/ausbildung/neuschonau-2864216?distance=10&category&latitude=48.88419&longitude=13.47576",
            },
            {
                img: [
                    "linear-gradient(#00000055, #00000055)",
                    require("@/assets/images/region/freyung-grafenau/cities/spiegelau.jpg"),
                ],
                title: "Spiegelau",
                alt: "Spiegelau",
                route: "/ausbildung/spiegelau-2835164?distance=10&category&latitude=48.91517&longitude=13.36229",
            },
            {
                img: [
                    "linear-gradient(#00000055, #00000055)",
                    require("@/assets/images/region/freyung-grafenau/cities/thurmannsbang.jpg"),
                ],
                title: "Thurmans-<br/>bang",
                alt: "Thurmansbang",
                route: "/ausbildung/thurmansbang-2822531?distance=10&category&latitude=48.76627&longitude=13.3155",
            },
            {
                img: [
                    "linear-gradient(#00000055, #00000055)",
                    require("@/assets/images/region/freyung-grafenau/cities/philipsreut.jpg"),
                ],
                title: "Philippsreut",
                alt: "Philippsreut",
                route: "/ausbildung/philippsreut-2853903?distance=10&category&latitude=48.86667&longitude=13.68333",
            },
        ],
    }),
    computed: {
        seoContentText() {
            return this.region?.about_us
        },
    },
    created() {
        this.randomLocations = this.locations
            .sort(() => 0.5 - Math.random())
            .slice(0, 6)
    },
}
